











import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import { encode } from "@common/util/base64Util";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "_id",
          "sort-desc": false,
        },
        content: {
          name: "Cashback",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("tenants", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                to: (value, row) => {
                  const filtersOrganization = {
                    "customPerPage": 0,
                    "dataOptions": {
                      "groupBy": [],
                      "groupDesc": [],
                      "itemsPerPage": 50,
                      "multiSort": false,
                      "mustSort": false,
                      "page": 1,
                      "sortBy": [
                        "date"
                      ],
                      "sortDesc": [
                        true
                      ]
                    },
                    "filters": [
                      {
                        "key": "point",
                        "operator": "greater_than",
                        "value": 0
                      }
                    ]
                  };
                  return 'cashback/' + row._id + '?table_options=' + encode(JSON.stringify(filtersOrganization));
                },
              },
            },
            totalPoints: {
              text: "Total"
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("cashBacks", "statics", options);
              return [items, count];
            },
          },
        },
      },
    };
  },
});

